import React, { useEffect, useState } from 'react';
import { Dialog } from '@capacitor/dialog';
import { ShareExtension } from 'capacitor-share-extension';
import { App } from '@capacitor/app';
import { Modal } from 'antd';
import { InputBox } from '../Auth/components/Inputbox';
import { Directory, Filesystem } from '@capacitor/filesystem';

const init = () => {
  const showAlert = async (json) => {
    await Dialog.alert({
      title: json.payload[0].title,
      message: json.payload[0].url + '\ntotal files:' + json.payload.length,
    });
  };

  ShareExtension.checkSendIntentReceived().then(async (data) => {
    console.log('Shared data:', data);
    console.log('Shared data:', JSON.stringify(data));
    console.log('Shared data:', data.payload[0].url);
    console.log('Shared data:', data.payload[0].title);
    await showAlert(data);
  });
};

// const handleShare = async (share) => {
//     console.log("handle ", share);
//     await showAlert(share);
// }
const IntentHandler = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState('');

  useEffect(() => {
    const initIOS = () => {
      const showAlert = async (json) => {
        await Dialog.alert({
          title: json.payload[0].title,
          message: json.payload[0].url + '\ntotal files:' + json.payload.length,
        });
      };

      const loadImage = async (filePath) => {
        try {
          // Get the image as base64 string or read it as text
          console.log('load image: ', filePath);
          const { data } = await Filesystem.readFile({
            path: filePath,
            // directory: Directory.Documents,
            encoding: 'base64', // Read it as base64
          });

          // Convert the base64 string to a format usable in an <img> tag
          const imageSrc = `data:image/png;base64,${data}`;

          // Now you can use imageSrc in an <img> tag or wherever you need
          setUrl(imageSrc);
          console.log('Loaded image data:', imageSrc);
          // Example: Set it in an img element
          // const imgElement = document.createElement('img');
          // imgElement.src = imageSrc;
          // document.body.appendChild(imgElement); // Or append to a specific element
        } catch (error) {
          console.error('Error loading image:', error);
        }
      };

      App.addListener('appUrlOpen', async (data) => {
        // Extract the shared data from the URL
        const sharedUrl = new URL(data.url);
        const sharedContent = sharedUrl.searchParams.get('url');
        console.log('Shared data:', data);

        if (sharedContent) {
          // Do something with the shared content (e.g., display it in your app)
          console.log('Shared URL: ', sharedContent.split('pms://share?url='));
          // await Dialog.alert({
          //     title: "title",
          //     message: sharedContent.split('pms://share?url=').pop(),
          // });
          setIsOpen(true);
          await loadImage(sharedContent.split('pms://share?imagePath=').pop());
          // setUrl(sharedContent.split('pms://share?url=').pop())
        }
      });
    };
    init();
    initIOS();
    console.log('init called');
  }, []);

  return (
    <>
      {' '}
      <Modal
        open={isOpen}
        onOk={() => {}}
        onCancel={() => {
          setIsOpen(false);
        }}
        footer={null}
        maskClosable={false}
        closable={true}
        centered={true}
        width={'31.25rem'}
        className="modelMaxHeight"
      >
        <h3 className="text-center mt-4 mb-3 txt-primary ff ff_bd f24 responsiveModalLabel">{`Add`}</h3>
        <img src={url} style={{ width: '100%', height: 100 }} />
        <div className="d-flex flex-column justify-content-center">
          <div className="mb-3"></div>

          <div className="mb-3">
            <InputBox label={`Email`}>
              <input
                type="email"
                value={url}
                onChange={(e) => {}}
                className="FormInput form-control"
                autoComplete="off"
                placeholder="Enter Email"
              />
            </InputBox>
          </div>
          <div
            className="d-flex justify-content-center mt-3 mb-4"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <button className="btn btn-primary px-5">{'Add'}</button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default IntentHandler;
